import React from "react";
import PropTypes from "prop-types";

const SubHeading = ({ children, className = "", style = {} }) => {
  return (
    <h3 className={"text-2xl md:text-3xl xl:text-4xl 2xl:text-5xl font-extrabold " + className} style={style}>
      {children}
    </h3>
  );
};

SubHeading.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
};

SubHeading.defaultProps = {
  className: "",
  style: {},
};

export default SubHeading;
