import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

function Button({ link, text, sideImg, style }) {
  return (
    <Link className="hover:cursor-pointer" to={link}>
      <button
        className="lg:min-w-[14rem] min-w-[10rem] sm:min-w-[12rem] inline-block font-bold rounded-3xl lg:h-[42px] h-[35px] md:text-[15px] lg:text-[20px] text-[14px] md:min-w-[9rem] lg:py-[5px]"
        style={style}
      >
        <div className="inline-flex items-center justify-center  px-3">
          {text}
          {sideImg}
        </div>
      </button>
    </Link>
  );
}

Button.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  style: PropTypes.shape({ backgroundColor: PropTypes.string, color: PropTypes.string, marginTop: PropTypes.number }),
  sideImg: PropTypes.string,
};

export default Button;
