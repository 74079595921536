import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../components/Button";

function EvolvingEnterprises({ bgImg, mobImg, title, leftText, rightText, bottomText }) {
  return (
    <div className="relative">
      <GatsbyImage className="enterprise-banner" image={bgImg} alt="background image" />
      <GatsbyImage className="enterprise-bannermob" image={mobImg} alt="background image" />
      <div className="absolute top-[5%] md:top-[2%] w-full">
        <div className="sm:text-[24px] sm:pb-[unset]  md:text-[24px] xl:text-[40px] font-extrabold flex justify-center items-center flex-col">
          <h2 className="pb-4 md:pb-[0px] text-[#0f1921] xl:pb-4 ">{title}</h2>
          <span className="text-[18px]">
            <Button
              link="https://entomo.co/request-demo/"
              text="let’s get started"
              style={{ backgroundColor: "#fcdb03" }}
            />
          </span>
        </div>
      </div>
      <div>
        <div className="flex w-full lg:top-48 sm:top-48 md:top-36 absolute top-2/3 flex-col sm:flex-row 2xl:top-96">
          <div className="sm:w-1/2 text-center">
            <span className="text-white font-medium sm:w-1/2 sm:text-2xl text-lg inline-block ">{leftText}</span>
          </div>
          <div className="sm:w-1/2 text-center mt-[0.9rem] md:mt-[unset]">
            <span className="text-white font-medium sm:w-1/2 sm:text-2xl text-lg inline-block ">{rightText}</span>
          </div>
        </div>
        <div className="absolute w-full top-[77%]">
          <div className="text-center w-full">
            <p className="md:w-1/2 text-white font-medium sm:text-2xl text-lg inline-block mb-[20px]">{bottomText}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

EvolvingEnterprises.propTypes = {
  bgImg: PropTypes.string,
  mobImg: PropTypes.string,
  title: PropTypes.string,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  bottomText: PropTypes.string,
};
export default EvolvingEnterprises;
