import { Link } from "gatsby";
import React from "react";
import PropTypes from "prop-types";

const BreadCrumbEntity = ({ text, link = "", className = "", style = {} }) => {
  let element = (
    <p className={className} style={style}>
      {text}
    </p>
  );
  if (link) {
    element = (
      <Link className={"hover:text-blue-700 " + className} to={link} style={style}>
        {text}
      </Link>
    );
  }
  return element;
};

BreadCrumbEntity.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

BreadCrumbEntity.defaultProps = {
  className: "",
  style: {},
  link: "",
};

export default BreadCrumbEntity;
