import React from "react";
import PropTypes from "prop-types";
import { ImCross } from "react-icons/im";

function Modal({ isOpen, closeModal, style, children, modalTitle, animate, hbStyle, btnstyle, contentClassName = "" }) {
  let transformItem = {};

  if (animate) {
    transformItem = {
      transform: isOpen ? "translateY(0vh)" : "translateY(-100vh)",
    };
  } else {
    transformItem = {
      display: isOpen ? "flex" : "none",
    };
  }
  return (
    <>
      <div>
        <div
          className="flex fixed transition-all  duration-1000 z-40 inset-0 items-center justify-center  h-screen w-full"
          style={transformItem}
        >
          <div className={`px-4 pb-4 transition-all delay-500 sm:w-full md:w-1/2 ${contentClassName}`} style={style}>
            <div className={`xl:p-4  flex items-end ${modalTitle ? "justify-between" : "justify-end"}`} style={hbStyle}>
              <div className="p-4 ">{modalTitle}</div>
              <button onClick={closeModal} className="flex p-4 text-white font-black hover:text-black" style={btnstyle}>
                <ImCross />
              </button>
            </div>
            {children}
          </div>
        </div>
      </div>
      <div
        className="flex fixed transition-all duration-1000 z-30 inset-0 h-screen w-full bg-gray-700 bg-opacity-70"
        style={{ display: isOpen ? "block" : "none" }}
      ></div>
    </>
  );
}
Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  style: PropTypes.shape({
    height: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
  }),
  children: PropTypes.node.isRequired,
  modalTitle: PropTypes.string,
  animate: PropTypes.bool,
  hbStyle: PropTypes.shape({
    height: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    margin: PropTypes.string.isRequired,
    alignItems: PropTypes.string.isRequired,
  }),
  btnstyle: PropTypes.shape({
    fontsize: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    opacity: PropTypes.string.isRequired,
  }),
  contentClassName: PropTypes.string,
};
Modal.deafultProps = {
  contentClassName: "",
};
export default Modal;
