import React from "react";
import PropTypes from "prop-types";

import BreadCrumbEntity from "../components/BreadCrumbEntity";

const BreadCrumb = ({ breadCrumbItems = [], className = "", style = {} }) => {
  let slash = "/";

  return (
    <div className={"flex gap-1 text-sm pt-5 " + className} style={style}>
      {breadCrumbItems.map((breadCrumbItem, index) => {
        if (index == breadCrumbItems.length - 1) {
          slash = "";
        }
        return (
          <>
            <BreadCrumbEntity
              key={breadCrumbItem.text}
              text={breadCrumbItem.text}
              link={breadCrumbItem.link}
              className={index == breadCrumbItems.length - 1 ? "font-bold" : ""}
            />
            {slash}
          </>
        );
      })}
    </div>
  );
};

BreadCrumb.propTypes = {
  breadCrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

BreadCrumb.defaultProps = {
  breadCrumbItems: [],
  className: "",
  style: {},
};

export default BreadCrumb;
