import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

function Banner({ bgImg, mobImg, children, bgColor, height, isHide }) {
  return (
    <div className="w-full relative">
      <div className="relative block " style={{ backgroundColor: bgColor, height: height }}>
        {!isHide && <GatsbyImage className=" banner-desktop-image" image={bgImg} alt="backgroundImage" />}
        {!isHide && <GatsbyImage className=" banner-mobile-image" image={mobImg} alt="mobImage" />}
      </div>
      <div className="w-full md:w-10/12 m-[auto] sm:block sm:max-w-[84%] flex justify-center">{children}</div>
    </div>
  );
}
Banner.propTypes = {
  bgImg: PropTypes.string,
  mobImg: PropTypes.string,
  children: PropTypes.node,
  bgColor: PropTypes.string,
  height: PropTypes.string,
  isHide: PropTypes.bool,
};

export default Banner;
