import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";

const Pagination = ({
  totalPages,
  currentPage,
  initialPage,
  paginationUrlAppendString = "",
  className = "",
  style = {},
}) => {
  const generalPageUrl = `${initialPage}${paginationUrlAppendString ? `/${paginationUrlAppendString}` : ""}/`;
  const pagesToDisplay = 5;
  const pagesOnBothSides = Math.floor(pagesToDisplay / 2);

  let leftMargin = currentPage - pagesOnBothSides;
  let rightMargin = currentPage + pagesOnBothSides;

  let shortage = null;
  if (leftMargin < 1 && rightMargin > totalPages) {
    leftMargin = 1;
    rightMargin = totalPages;
  } else if (leftMargin < 1) {
    shortage = leftMargin * -1;
    rightMargin += shortage;
    leftMargin = 1;
  } else if (rightMargin > totalPages) {
    shortage = rightMargin - totalPages;
    leftMargin -= shortage;
    rightMargin = totalPages;
  }

  const ThreeDots = () => {
    return (
      <div className="flex gap-1 mx-2">
        {Array.from({ length: 3 }).map((_, index) => (
          <span key={index} className="self-center w-1 h-1 bg-black rounded-full"></span>
        ))}
      </div>
    );
  };

  return (
    <div className={`flex items-center gap-1 ${className}`} style={style}>
      {(currentPage !== 1 && (
        <Link
          to={currentPage === 2 ? initialPage : `${generalPageUrl}${currentPage - 1}`}
          className="block p-2 font-bold text-black"
        >
          <div className="flex items-center gap-1">
            <BiChevronsLeft />
            <span>Prev</span>
          </div>
        </Link>
      )) ||
        null}

      <Link
        className={`px-3 py-1 font-bold rounded-md ${
          currentPage === 1 ? "bg-primary-blue-dark text-white" : "text-black"
        }`}
        to={initialPage}
      >
        1
      </Link>
      {(currentPage > pagesToDisplay && <ThreeDots></ThreeDots>) || null}

      {Array.from({ length: totalPages }).map((_, index) => {
        const pageTheLoopIsReferringTo = index + 1;
        if (!(pageTheLoopIsReferringTo === 1 || pageTheLoopIsReferringTo === totalPages)) {
          let link = `${generalPageUrl}${pageTheLoopIsReferringTo}`;
          if (pageTheLoopIsReferringTo >= leftMargin && pageTheLoopIsReferringTo <= rightMargin) {
            return (
              <Link
                className={`px-3 py-1 font-bold rounded-md ${
                  currentPage === pageTheLoopIsReferringTo ? "bg-primary-blue-dark text-white" : "text-black"
                }`}
                key={pageTheLoopIsReferringTo}
                to={link}
              >
                {pageTheLoopIsReferringTo}
              </Link>
            );
          } else {
            return null;
          }
        }
      })}
      {(currentPage - 1 < totalPages - pagesToDisplay && <ThreeDots></ThreeDots>) || null}
      <Link
        className={`px-3 py-1 font-bold rounded-md ${
          currentPage === totalPages ? "bg-primary-blue-dark text-white" : "text-black"
        }`}
        to={`${generalPageUrl}${totalPages}`}
      >
        {totalPages}
      </Link>
      {(currentPage !== totalPages && (
        <Link to={`${generalPageUrl}${currentPage + 1}`} className="p-2 font-bold text-black">
          <div className="flex items-center gap-1">
            <span>Next</span>
            <BiChevronsRight />
          </div>
        </Link>
      )) ||
        null}
    </div>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  initialPage: PropTypes.string.isRequired,
  paginationUrlAppendString: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

Pagination.defaultProps = {
  className: "",
  style: {},
  paginationUrlAppendString: "",
};

export default Pagination;
