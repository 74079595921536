import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaPlay, FaChevronRight } from "react-icons/fa";

import { featuredConversationsVariant, allConversationsVariant } from "../constants/conversation_card-variants";

const VideoCard = ({
  className = "",
  style = {},
  image,
  title,
  desc,
  link,
  variant = allConversationsVariant,
  onClick,
}) => {
  return (
    <div
      className={
        (variant == featuredConversationsVariant ? "md:flex-row" : "") +
        " flex flex-col  shadow-around rounded-lg overflow-hidden " +
        className
      }
      style={style}
      onClick={onClick}
    >
      <div
        className={
          (variant == featuredConversationsVariant ? "md:w-1/2 lg:h-auto lg:w-1/3 xl:h-44 " : "") +
          " relative flex items-center justify-around h-40 bg-cover bg-center"
        }
      >
        <GatsbyImage className="video-card-cover" image={image} alt="cover"></GatsbyImage>
        <FaPlay className="absolute" size="2rem" color="#FFF" />
      </div>
      <div className={(variant == featuredConversationsVariant ? "md:w-1/2 lg:2/3" : "") + " p-3"}>
        <div className="bg-gray-400 h-[1px]"></div>
        <div className={(variant == featuredConversationsVariant ? "font-bold" : "") + " text-sm mb-7"}>
          <p>{title}</p>
          <p>{desc}</p>
        </div>
        <a to={link} className="text-sm text-[#0064bf]">
          <div className="flex items-center gap-1 mt-11">
            <span>watch video</span> <FaChevronRight size="0.5rem" />
          </div>
        </a>
      </div>
    </div>
  );
};

VideoCard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([allConversationsVariant, featuredConversationsVariant]),
  onClick: PropTypes.func,
};

VideoCard.defaultProps = {
  className: "",
  style: {},
  variant: allConversationsVariant,
};

export default VideoCard;
