import React, { useState } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../containers/global/layout";
import Seo from "../components/seo";
import SubHeading from "../components/SubHeading";
import BreadCrumb from "../containers/BreadCrumb";
import VideoCard from "../containers/VideoCard";
import Container from "../utils/Container";
import HubspotForm from "../components/HubspotForm";
import EvolvingEnterprises from "../containers/EvolvingEnterprises";
import Pagination from "../components/Pagination";
import Banner from "../containers/Banner";
import Modal from "../components/modal";

import { featuredConversationsVariant } from "../constants/conversation_card-variants";

const Index = ({ data, pageContext }) => {
  const allConversations = data.allConversations.nodes;
  const featuredConversations = data.featuredConversations.nodes;
  const allHeadings = data.allHeadings.nodes;
  const bannerData = data.bannerData.nodes[0];
  const evolvingEnterpriseData = data.evolvingEnterpriseData.nodes[0];
  const hubspotFormData = data.hubspotFormData.nodes[0];

  const [isModalOpen, setisModalOpen] = useState(false);
  const [modalLink, setModalLink] = useState(null);

  const modelOpenHandler = (link) => {
    setisModalOpen(true);
    setModalLink(link);
  };

  return (
    <Layout>
      <Seo title="entomo Community | entomo" />
      <Modal
        isOpen={isModalOpen}
        closeModal={() => {
          setisModalOpen(false);
        }}
        modalPath={modalLink}
        style={{ height: "75vh" }}
      />
      <Banner bgImg={bannerData.desktopImage.gatsbyImageData} mobImg={bannerData.mobileImage.gatsbyImageData}>
        <div className="absolute top-1 w-full px-5 text-center sm:left-[3%] sm:text-left sm:w-2/3 md:w-[46%] lg:top-[30%]">
          <h2 className="mb-5 text-3xl font-extrabold md:text-4xl xl:text-5xl">{bannerData.title}</h2>
          <p>{bannerData.description.description}</p>
        </div>
      </Banner>
      <Container>
        <BreadCrumb
          className="mt-5"
          breadCrumbItems={[
            {
              text: "home",
              link: "/",
            },
            {
              text: "inspiration",
            },
            {
              text: "entomo community",
            },
          ]}
        ></BreadCrumb>
        <section className="mb-10">
          <SubHeading className="text-[#0064bf] my-10">
            {allHeadings.find((heading) => heading.identifier === "heading1")?.text}
          </SubHeading>
          {featuredConversations.map((conversation) => {
            return (
              <VideoCard
                key={conversation.title}
                className="my-5 lg:w-1/2"
                image={conversation.image.gatsbyImageData}
                title={conversation.title}
                desc={conversation.subHeading}
                link={conversation.videoUrl}
                variant={featuredConversationsVariant}
                onClick={() => modelOpenHandler(conversation.videoUrl)}
              ></VideoCard>
            );
          })}
        </section>
        <section className="mb-10">
          <SubHeading className="my-5">
            {allHeadings.find((heading) => heading.identifier === "heading2")?.text}
          </SubHeading>
          <section className="md:flex md:gap-7 lg:gap-9">
            <section className="grid lg:grid-cols-2 gap-7 md:w-[65%]">
              {allConversations.map((conversation) => {
                return (
                  <VideoCard
                    key={conversation.title}
                    image={conversation.image.gatsbyImageData}
                    title={conversation.title}
                    desc={conversation.subHeading}
                    link={conversation.videoUrl}
                    onClick={() => modelOpenHandler(conversation.videoUrl)}
                  ></VideoCard>
                );
              })}
              <Pagination
                className="flex mx-auto my-5 w-fit md:hidden"
                totalPages={pageContext.numPages}
                currentPage={pageContext.currentPage}
                initialPage="/entomo-community"
                paginationUrlAppendString="page"
              ></Pagination>
            </section>
            <div className="my-5 md:w-[35%] md:my-0 md:self-start text-center bg-primary-yellow-dark px-8 py-24 rounded-lg shadow-around">
              <h3 className="text-3xl font-bold text-center md:text-2xl lg:text-3xl">{hubspotFormData.title}</h3>
              <p className="md:text-sm lg:text-base">{hubspotFormData.description.description}</p>
              <HubspotForm
                portalId="1750400"
                formId="542ff168-80b1-4e99-9980-42222ba880ca"
                containerId="hbspt-webinar-form"
              ></HubspotForm>
            </div>
          </section>
          <Pagination
            className="hidden mx-auto my-5 w-fit md:flex"
            totalPages={pageContext.numPages}
            currentPage={pageContext.currentPage}
            initialPage="/entomo-community"
            paginationUrlAppendString="page"
          ></Pagination>
        </section>
      </Container>
      <EvolvingEnterprises
        bgImg={evolvingEnterpriseData.desktopImage.gatsbyImageData}
        mobImg={evolvingEnterpriseData.mobileImage.gatsbyImageData}
        title={evolvingEnterpriseData.heading}
        leftText={evolvingEnterpriseData.leftText}
        rightText={evolvingEnterpriseData.rightText}
        bottomText={evolvingEnterpriseData.bottomText}
      />
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default Index;

export const query = graphql`
  query CommunityData($skip: Int!, $limit: Int!) {
    allConversations: allContentfulCoversations(
      filter: { metadata: { tags: { elemMatch: { name: { in: ["page: entomo_community", "region: global"] } } } } }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        image {
          gatsbyImageData(formats: WEBP)
        }
        videoUrl
        title
        subHeading
        featured
      }
    }

    featuredConversations: allContentfulCoversations(
      filter: {
        featured: { eq: true }
        metadata: { tags: { elemMatch: { name: { in: ["page: entomo_community", "region: global"] } } } }
      }
    ) {
      nodes {
        image {
          gatsbyImageData(formats: WEBP)
        }
        videoUrl
        title
        subHeading
        featured
      }
    }

    allHeadings: allContentfulHeading(
      filter: { metadata: { tags: { elemMatch: { name: { in: ["page: entomo_community", "region: global"] } } } } }
    ) {
      nodes {
        text
        identifier
      }
    }

    bannerData: allContentfulBanner(
      filter: { metadata: { tags: { elemMatch: { name: { in: ["page: entomo_community", "region: global"] } } } } }
    ) {
      nodes {
        description {
          description
        }
        desktopImage {
          gatsbyImageData(formats: WEBP)
        }
        mobileImage {
          gatsbyImageData(formats: WEBP)
        }
        title
      }
    }

    evolvingEnterpriseData: allContentfulEvolvingEnterprises(
      filter: { metadata: { tags: { elemMatch: { name: { in: ["page: entomo_community", "region: global"] } } } } }
    ) {
      nodes {
        bottomText
        leftText
        rightText
        heading
        desktopImage {
          gatsbyImageData(formats: WEBP)
        }
        mobileImage {
          gatsbyImageData(formats: WEBP)
        }
      }
    }

    hubspotFormData: allContentfulHubspotForm(
      filter: { metadata: { tags: { elemMatch: { name: { in: ["page: entomo_community", "region: global"] } } } } }
    ) {
      nodes {
        description {
          description
        }
        title
      }
    }
  }
`;
